.Toastify__toast-body {
  font-size: 17px !important;
}

::-webkit-scrollbar {
  width: 12px;
  /* Slightly wider for better visibility */
  height: 12px;
  /* For horizontal scrollbars */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Light background */
  border-radius: 6px;
  /* Rounded corners for a smoother look */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  /* Neutral color */
  border-radius: 6px;
  /* Rounded corners */
  border: 3px solid #f1f1f1;
  /* Slight border to make thumb stand out */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Darker color for hover effect */
}

/* Optional: Handle for hover state with transition */
::-webkit-scrollbar-thumb {
  transition: background-color 0.3s ease;
  /* Smooth transition effect */
}


.scroll-container {
  max-height: 200px;
  /* Adjust based on your design needs */
  overflow: auto;
  /* Enable scrolling */
  position: relative;
  /* Position relative for possible future adjustments */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  width: 0;
  /* Hide vertical scrollbar */
  height: 0;
  /* Hide horizontal scrollbar */
}

/* Hide scrollbar for Firefox */
.scroll-container {
  scrollbar-width: none;
  /* Hide scrollbar */
}

/* Hide scrollbar for Internet Explorer and Edge */
.scroll-container {
  -ms-overflow-style: none;
  /* Hide scrollbar */
}

.footerFixed {
  position: fixed;
  bottom: 15px;
  z-index: -1;
}

.footerFixed-forHome {
  position: fixed;
  bottom: 15px;
}

.custom-modal {
  /* z-index: 50 !important */
  z-index: 1200 !important
}

.z-1300 {
  z-index: 1300 !important
}

.justify-content-end {
  display: flex !important;
  justify-content: end !important;
}